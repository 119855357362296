import React,{useState, useEffect} from 'react'
import MainView from "../components/MainView/MainView"
import Layout from "../components/layout"
import { useMediaQuery } from 'react-responsive';
import useWindowSize from '../hooks/useGatsbyWindowSize'



const OptionPage = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();

  //console.log( 'mobile :' + isMobile );


useEffect(() => {

  //console.log(mobile);
  setIsLoaded(true);


},[mobile]);



if( mobile && isLoaded ){
return (

  <Layout>
    {/*<MainView items={1} mobile={true} width={width} height={height} location={props.location} />*/}
    <MainView items={2} mobile={true} width={width} curveTop={0} curveHeight={60} height={height} location={props.location}  />

  </Layout>

);
}
if( !mobile && isLoaded){
  return (

    <Layout>
      <MainView items={4} mobile={false} width={width} curveTop={-200} curveHeight={60} height={height} location={props.location}  />

    </Layout>

  );
}
}


export default OptionPage
